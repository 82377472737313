import React, { Component } from 'react';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { Link } from 'gatsby';

import { mediumBreakpointMinus, imageBase } from '../constants';
import PropTypes from 'prop-types';

const MainContentBox = styled.div`
  height: 100%;
  width: 100vw;
  margin: 30px auto 0 auto;
  background: white;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: ${props => (props.roomyLayout ? 'space-evenly' : 'unset')};

  @media only screen and (max-width: ${mediumBreakpointMinus}) {
    flex-direction: column;
  }
`;

const SquareImage = styled.img`
  width: ${props => (props.imageWidth ? props.imageWidth : '30vw')};
  height: auto;
  @media only screen and (max-width: ${mediumBreakpointMinus}) {
    width: 100vw;
    margin-top: 0 !important;
  }
`;

const HalfColumnBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @media only screen and (max-width: ${mediumBreakpointMinus}) {
    width: 100vw !important;
  }
`;

class InfoPage extends Component {
  disableRightClick() {
    // e.preventDefault();
  }

  render() {
    const {
      title,
      keywords,
      children,
      imgSrc,
      imgSrc2x,
      alt,
      imageLink = '/',
      description,
      imageMarginTop,
      customImageWidth,
      roomyLayout,
    } = this.props;
    const srcSet = `${imageBase + imgSrc} 1x, ${imageBase + imgSrc2x} 2x`;
    const imageWidth = customImageWidth ? `${customImageWidth}vw` : '50vw';
    const halfColumnWidth =
      customImageWidth && !roomyLayout ? `${100 - customImageWidth}vw` : '50vw';

    const renderImage = imageWidth => {
      return (
        <SquareImage
          src={`${imageBase + imgSrc}`}
          srcSet={srcSet}
          alt={alt}
          imageWidth={imageWidth}
          style={imageMarginTop ? { marginTop: `${imageMarginTop}px` } : null}
        />
      );
    };

    const isLink = imageLink !== '/';

    return (
      <Layout>
        <SEO title={title} keywords={keywords} description={description} />
        <MainContentBox
          onContextMenu={e => this.disableRightClick(e)}
          roomyLayout={roomyLayout}
        >
          {!isMobile && (
            <div style={{ width: imageWidth }}>
              {isLink ? (
                <Link to={imageLink} style={{ width: imageWidth }}>
                  {renderImage(imageWidth)}
                </Link>
              ) : (
                renderImage(imageWidth)
              )}
            </div>
          )}
          <HalfColumnBox style={{ width: halfColumnWidth }}>
            {children}
          </HalfColumnBox>
        </MainContentBox>
      </Layout>
    );
  }
}

InfoPage.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  description: PropTypes.string,
  keywords: PropTypes.array,
  imgSrc: PropTypes.string,
  imgSrc2x: PropTypes.string,
  imageLink: PropTypes.string,
  alt: PropTypes.string,
  imageMarginTop: PropTypes.number,
  customImageWidth: PropTypes.number,
  roomyLayout: PropTypes.bool,
};

export default InfoPage;
